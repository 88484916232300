/* This is copied from article-editor stylesheet to support grid in RedactorX editor */

.rx-content .grid {
display: grid;
grid-template-columns: repeat(12, 1fr);
grid-column-gap: 24px;
grid-row-gap: 12px; }
.rx-content .column {
margin: 0; }
.rx-content .column-1 {
grid-column: span 1; }
.rx-content .column-2 {
grid-column: span 2; }
.rx-content .column-3 {
grid-column: span 3; }
.rx-content .column-4 {
grid-column: span 4; }
.rx-content .column-5 {
grid-column: span 5; }
.rx-content .column-6 {
grid-column: span 6; }
.rx-content .column-7 {
grid-column: span 7; }
.rx-content .column-8 {
grid-column: span 8; }
.rx-content .column-9 {
grid-column: span 9; }
.rx-content .column-10 {
grid-column: span 10; }
.rx-content .column-11 {
grid-column: span 11; }
.rx-content .column-12 {
grid-column: span 12; }
@media only screen and (max-width: 767px) {
.rx-content .grid {
    display: block; } }
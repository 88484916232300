/* paragraph */
.arx-content p.st-lead {
    font-size: 25px;
    color: #444;
}
.arx-content p.st-note {
    padding: 26px;
    background-color: #fffcba;
    font-size: 16px;
    color: #111;
}
.arx-content p.st-accent {
    font-size: 39px;
    font-weight: bold;
    line-height: 1.35;
    padding-top: 13px;
    padding-bottom: 26px;
    border-top: 4px solid #333;
    border-bottom: 4px solid #333;
}

/* embed */
.arx-content .st-embed-frame {
    border: 39px solid #111;
}
.arx-content .st-embed-frame figcaption {
    margin-top: 0;
    padding-top: 8px;
    background: #111;
    color: #ccc;
}
.arx-content .st-embed-raised {
    background-color: #fff;
    padding: 12px;
    border-radius: 2px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .35);
}
.arx-content .st-embed-raised figcaption {
    margin-top: 4px;
}

/* line */
.arx-content hr.st-line-black-extra-height:before  {
    border-top: 4px solid #111;
}
.arx-content hr.st-line-gray-dashed {
    margin-top: 26px;
    margin-bottom: 26px;
}
.arx-content hr.st-line-gray-dashed:before  {
    border-top: 2px dashed #ccc;
}
.arx-content hr.st-line-blue:before  {
    border-top: 2px solid #458fff;
}
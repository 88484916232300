.arx-popup-icons {
    min-width: 170px;
    max-width: 170px;
    padding: 0;
}
.arx-popup-body-icons {
    display: flex;
    flex-wrap: wrap;
}
.arx-popup-body-icons .arx-button-popup {
    margin-bottom: -1px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #eee;
    color: #111;
    border-left-color: transparent;
}
.arx-popup-body-icons .arx-button-popup:hover {
    color: #111;
    background-color: #e4e4e4;
}
.arx-popup-body-icons .arx-button-title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
	vertical-align: middle;
}